import React from 'react';
import Layout from '../../../components/Layout';
import { Container, Row, Column } from '../../../components/Grid';
import Section from '../../../components/Section';
import { Link } from 'gatsby';
import Quicklinks from '../../../components/Quicklinks';
import Image from '../../../components/Image';
import Icon from '../../../components/Icon';
import Video from '../../../components/Video';

const RainbowBubbleSnake = () => (
  <Layout
    title="Rainbow Bubble Snake - Science at Home"
    className="science-starters has-video"
  >
    {/* Intro Title and Video */}
    <Section className="science-starters__video">
      <Container>
        {/* Breadcrumbs */}
        <ul className="breadcrumbs no-hero">
          <li className="breadcrumb">
            <Link to="../../getting-started">Science Starters</Link>
          </li>
          <li className="breadcrumb">
            <Link to="../../science-at-home">Science at Home</Link>
          </li>
          <li className="breadcrumb active">Rainbow Bubble Snake</li>
        </ul>
        <Row>
          <Column size={10} offset={1} className="no-hero">
            <h1 className="page-title">Rainbow Bubble Snake</h1>
            {/* Video Container */}
            <div className="video-container__with-pager">
              <Link to="../elephant-toothpaste" className="previous">
                <Icon name="arrowleftgray" />
              </Link>
              <Video
                guidSrc="9f5071b0-4f61-4a0c-9f73-664f00bd6599"
                className="de-vid"
              ></Video>
              <Link to="../fidget-spinner" className="next">
                <Icon name="arrowrightgray" />
              </Link>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Main Content */}
    <Section className="pt-0 pb-6">
      <Container>
        <Row className="mb-4">
          <Column size={8} offset={1}>
            <h2 className="science-at-home__intro">
              Have you ever wondered how bubbles form? Learn the answer by
              joining special guest Kate the Chemist as she shows you how to
              make a bright and beautiful bubble snake using just a few simple
              materials.
            </h2>
          </Column>
        </Row>
        <Row>
          <Column size={8} offset={1} className="science-home__content">
            {/* Quick Links */}
            <Quicklinks
              className="pl-0"
              title="Quicklinks:"
              smoothScroll
              links={[
                {
                  target: '#background',
                  label: 'Background',
                },
                {
                  target: '#key-concepts',
                  label: 'Key Concepts',
                },
                {
                  target: '#materials',
                  label: 'Materials',
                },
                {
                  target: '#safety',
                  label: 'Safety',
                },
                {
                  target: '#standards',
                  label: 'Standards',
                },
              ]}
            />
            {/* Background */}
            <h3 id="background">Background</h3>
            <p>
              Bubbles are pockets of soap and water that are filled with air –
              like your breath, which contains nitrogen, oxygen and carbon
              dioxide. When you combine soap and water together then blow air
              into the mixture, the soap forms a thin wall and traps the air –
              creating a bubble! The soapy film is composed of three layers: one
              layer of water molecules between two layers of soap molecules.
              Millions of these molecules will stick together to form the
              spherical shape of a bubble.
            </p>
            {/* Key Concepts */}
            <h3 id="key-concepts">Key Concepts</h3>
            <ul>
              <li>Chemistry</li>
              <li>Gases</li>
              <li>Molecules</li>
            </ul>
            {/* Materials */}
            <h3 id="materials">Materials</h3>
            <ul>
              <li>An adult helper</li>
              <li>1 small plastic bottle</li>
              <li>1 thin towel (or sock)</li>
              <li>1 rubber band</li>
              <li>3-4 drops of food coloring (variety of colors)</li>
              <li>½ cup water</li>
              <li>¼ cup dish soap</li>
              <li>1 small bowl</li>
              <li>1 precision knife or scissors</li>
            </ul>
            <h3>Preparation</h3>
            <ul>
              <li>
                Have an adult use the precision knife or scissors to cut the
                bottom ½ inch off a plastic bottle.
              </li>
              <li>
                Use a rubber band to fasten the towel to the open side of the
                bottle.
              </li>
              <li>
                Add the water and dish soap to the small bowl. Swirl to stir.
              </li>
              <li>
                Add food coloring directly to the towel. Get creative here – try
                to make a fun pattern!
              </li>
              <li>Dip the towel into the soap solution.</li>
              <li>
                Blow with force into the mouthpiece of the plastic bottle.
              </li>
              <li>Check it out – you’ve just made a bubble snake!</li>
            </ul>
            <h3>Observation and Results</h3>
            <p>Why did we add dish soap to the water solution?</p>
            <p>What is the gas trapped inside of the bubbles?</p>
            <p>
              What will happen if you double the amount of dish soap in the
              water solution?{' '}
            </p>
            <p>
              What will happen if you use a 3-liter soda bottle? Will the
              bubbles be bigger?
            </p>
            <p>
              What will happen if you use a thick towel instead of a thin towel?
            </p>
            <p>
              The towel plays an important role in this experiment, as the dish
              soap is absorbed into the towel fibers until we use our breath to
              push our exhaled gas into a bubble.
            </p>
            <p>
              You may notice some towels work better than others; the best ones
              are thick enough to absorb the bubble solution, but thin enough
              that you are can easily push air through the fibers.
            </p>
            <p>
              And why do we add the food coloring to the towel instead of the
              water solution? Ideally, we want to create a multicolored chain of
              bubbles. If we add the food coloring to the towel, we can maintain
              sections of orange, red, green, blue, purple or whatever colors
              you chose! This is perfect for creating a rainbow bubble snake.
              However, if we had added all of these colors to the water
              solution, our water would turn dark black. That solution would
              give us a chain of black bubbles instead of a colorful bubble
              snake!
            </p>
            <p>
              Keep trying out different color variations, different towel sizes,
              and make your observations.
            </p>
            <p>
              When you are finished, remember to clean up when you are done by
              disposing of or recycling your materials.
            </p>
            {/* Safety */}
            <h3 id="safety">Safety First and Adult Supervision</h3>
            <ul>
              <li>Follow the experiment’s instructions carefully.</li>
              <li>A responsible adult should assist with each experiment.</li>
              <li>
                While science experiments at home are exciting ways to learn
                about science hands-on, please note that some may require
                participants to take extra safety precautions and/or make a
                mess.
              </li>
              <li>
                Adults should handle or assist with potentially harmful
                materials or sharp objects.
              </li>
              <li>
                Adult should review each experiment and determine what the
                appropriate age is for the student’s participation in each
                activity before conducting any experiment.
              </li>
            </ul>
            {/* Standards */}
            <h3 id="standards">
              Next Generation Science Standard (NGSS) Supported - Disciplinary
              Core Ideas
            </h3>
            <p>
              This experiment was selected for Science at Home because it
              teaches NGSS Disciplinary Core Ideas, which have broad importance
              within or across multiple science or engineering disciplines.
            </p>
            <p>
              Learn more about how this experiment is based in NGSS Disciplinary
              Core Ideas.
            </p>
            <p className="bold">
              Engineering Design (ETS)1: Engineering Design
            </p>
            <div className="accordion">
              <div className="accordion__group">
                <div className="accordion__item">
                  <input type="checkbox" id="chck1"></input>
                  <label className="label" for="chck1">
                    PS1.A: Structure and Properties of Matter
                  </label>
                  <div className="content">
                    <h4>Grades K-2</h4>
                    <ul>
                      <li>
                        <em>2-PS1-1.</em> Different kinds of matter exist and
                        many of them can be either solid or liquid depending on
                        temperature. Matter can be described and classified by
                        its observable properties.
                      </li>
                      <li>
                        <em>2-PS1-2.</em> Different properties are suited to
                        different purposes.
                      </li>
                      <li>
                        <em>2-PS1-3.</em> A great variety of objects can be
                        built up from a small set of pieces.
                      </li>
                    </ul>
                    <h4>Grades 3-5</h4>
                    <ul>
                      <li>
                        <em>5-PS1-1.</em>
                        <ul>
                          <li>
                            Matter of any type can be subdivided into particles
                            that are too small to see, but even then, the matter
                            still exists and can be detected by other means.
                          </li>
                          <li>
                            A model shows that gases are made from matter
                            particles that are too small to see and are moving
                            freely around in space. This can explain many
                            observations, including the inflation and shape of a
                            balloon and the effects of air on larger particles
                            or objects.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <em>5-PS1-2.</em> The amount (weight) of matter is
                        conserved when it changes form, even in transitions in
                        which it seems to vanish.
                      </li>
                      <li>
                        <em>5-PS1-3.</em> Measurements of a variety of
                        properties can be used to identify materials.
                      </li>
                    </ul>
                    <h4>Grades 6-8</h4>
                    <ul>
                      <li>
                        <em>MS-PS1-1.</em>
                        <ul>
                          <li>
                            Substances are made from different types of atoms,
                            which combine with one another in various ways.
                          </li>
                          <li>
                            Atoms form molecules that range in size from two to
                            thousands of atoms.
                          </li>
                          <li>
                            Solids may be formed from molecules, or they may be
                            extended structures with repeating subunits.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <em>MS-PS1-1-2, 3.</em>
                        <ul>
                          <li>
                            Each pure substance has characteristic physical and
                            chemical properties that can be used to identify it.
                          </li>
                        </ul>
                      </li>
                      <li>
                        <em>MS-PS1-4.</em>
                        <ul>
                          <li>
                            Gases and liquids are made of molecules or inert
                            atoms that are moving about relative to each other.
                          </li>
                          <li>
                            In a liquid, the molecules are constantly in contact
                            with others.
                          </li>
                          <li>
                            In a gas, they are widely spaced except when they
                            happen to collide.
                          </li>
                          <li>
                            In a solid, atoms are closely spaced and may vibrate
                            in position but do not change relative locations.
                          </li>
                          <li>
                            The changes of state that occur with variations in
                            temperature or pressure can be described and
                            predicted using these models of matter.
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <h4>Grades 9-12</h4>
                    <ul>
                      <li>
                        <em>HS-PS1-1.</em> Each atom has a charged substructure
                        consisting of a nucleus, which is made of protons and
                        neutrons, surrounded by electrons.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="accordion__item">
                  <input type="checkbox" id="chck2"></input>
                  <label className="label" for="chck2">
                    Physical Science (PS)1: Matter and Its Interactions
                  </label>
                  <div className="content">
                    <h4>Grades 3-5</h4>
                    <ul>
                      <li>
                        <em>5-PS1-4.</em> When two or more different substances
                        are mixed. A new substance with different properties may
                        be formed.
                      </li>
                    </ul>
                    <h4>Grades 6-8</h4>
                    <ul>
                      <li>
                        <em>MS-PS1-2.</em> Substances react chemically in
                        characteristic ways.
                      </li>
                      <li>
                        <em>MS-PS1-3.</em> In a chemical process, the atoms that
                        make up the original substances are regrouped into
                        different molecules, and these new substances have
                        different properties from those of the reactants.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="accordion__item">
                  <input type="checkbox" id="chck3"></input>
                  <label className="label" for="chck3">
                    PS2.A: Forces and Motion
                  </label>
                  <div className="content">
                    <h4>Grades K-2</h4>
                    <ul>
                      <li>
                        <em>K-PS2-1.</em> Pushes and pulls can have different
                        strengths and directions.
                      </li>
                      <li>
                        <em>K-PS2-2.</em> Pushing or pulling on an object can
                        change the speed or direction of its motion and can
                        start or stop it.
                      </li>
                    </ul>
                    <h4>Grades 3-5</h4>
                    <ul>
                      <li>
                        <em>3-PS2-1.</em> Each force acts on one particular
                        object and has both strength and direction. An object
                        typically at rest has multiple forces acting on it., but
                        they add to give zero net force on the object. Forces
                        that do not sum to zero can cause changes in the
                        object’s speed or direction of motion.
                      </li>
                      <li>
                        <em>3-PS2-2.</em> The patterns of an object’s motion in
                        various situations can be observed and measured; when
                        that past motion exhibits a regular patter, future
                        motion can be predicted from it.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="accordion__item">
                  <input type="checkbox" id="chck4"></input>
                  <label className="label" for="chck4">
                    PS2.B: Types of Interactions
                  </label>
                  <div className="content">
                    <h4>Grades 3-5</h4>
                    <ul>
                      <li>
                        <em>3-PS2-1.</em> Objects in contact exert forces on
                        each other.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Column>
          {/* PI team still trying to decide if we're going 
              to use these callouts prior to site launch
          <Column size={4}>
            <div className="global__profile-box box-shadow">
              <div className="career-right-image">
                <Image filename="RainbowSnake-Profile-Square.jpg" />
              </div>
              <div className="global__profile-content">
                <h3>Kate the Chemist</h3>
                <h4>Chemist, 3M</h4>
              </div>
            </div>
          </Column> */}
        </Row>
      </Container>
    </Section>
    {/* Explore Additional Science at Home Videos and Activities */}
    <Section className="science-at-home__cards pb-6">
      <Container>
        <Row>
          <Column fullWidth>
            <h2>Explore Additional Science at Home Videos and Activities</h2>
          </Column>
        </Row>
        <Row>
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="ElephantToothpaste-Thumbnail.png" />
              <div className="card__content">
                <h3>Elephant Toothpaste</h3>
                <p>
                  Join Camille Schrier, a scientist who was crowned Miss America
                  2020, as she shows you how to make a chemical reaction so big
                  it’s fit for elephants!
                </p>
              </div>
              <Link
                to="../elephant-toothpaste"
                className="button bg-gradient-purple"
              >
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="FidgetSpinner-Thumbnail.png" />
              <div className="card__content">
                <h3>Fidget Spinner</h3>
                <h4>
                  Your teacher might not love when you have one in class, but
                  the physics behind fidget spinners are truly head-spinning!
                </h4>
                <p>
                  Join 3M scientist Tesha R.-Alston Dampier as she shows you how
                  a spinning motion changes the way things move.
                </p>
              </div>
              <Link
                to="../fidget-spinner"
                className="button bg-gradient-purple"
              >
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="LiquidFireworks-Thumbnail.png" />
              <div className="card__content">
                <h3>Liquid Fireworks</h3>
                <p>
                  Join 3M scientist Jeff Payne as he uses nothing more than
                  milk, dish soap, and a few other kitchen supplies to get the
                  amazing effects of fireworks without using any fire at all.
                </p>
              </div>
              <Link
                to="../liquid-fireworks"
                className="button bg-gradient-purple"
              >
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default RainbowBubbleSnake;
